export default {
  "medicalAct": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un traitement"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le traitement"])},
    "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement manuel"])},
    "codedNone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun traitement codé"])},
    "predictedNone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun traitement prédit"])},
    "promote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter le traitement"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["traitement"]), _normalize(["traitements"])])},
    "uncommonWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le traitement est inconnu de la CHOP, il n'est pas pris en compte dans le groupage"])}
  },
  "rehabilitationAct": {
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["traitement"]), _normalize(["traitements"])])}
  },
  "automation": {
    "mode": {
      "automatable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séjours automatisables"])},
      "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séjours en attente d'informations"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séjours à faire manuellement"])}
    }
  },
  "dependency": {
    "depBehaviour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comportement"])},
    "depCognitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognitive"])},
    "depContinence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continence"])},
    "depDressing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habillage"])},
    "depFeeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimentation"])},
    "depMoving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacement et locomotion"])},
    "depPhysical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physique"])},
    "depSocial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication"])}
  },
  "diagnosis": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous diagnostics"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AE"])},
    "DP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DP"])},
    "DR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC"])},
    "DAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS"])},
    "MMP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MMP"])}
  },
  "filter": {
    "labels": {
      "absentBothAEDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AE absents"])},
      "absentBothAssociatedDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS absents"])},
      "absentBothDASDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAS absents"])},
      "absentBothGlobalDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnostics absents"])},
      "absentBothMMPDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MMP absents"])},
      "absentBothPrincipalDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DP absents"])},
      "absentBothRelatedDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC absents"])},
      "absentContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots-clés absents"])},
      "absentCurrentGhm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GHM absents"])},
      "absentCurrentGhmLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sévérités prédites absentes"])},
      "absentCurrentGhmType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types de racine GHM absents"])},
      "absentInitialGme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GME absents pré-revalo"])},
      "absentCurrentGme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GME absents"])},
      "absentDivision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divisions absentes"])},
      "absentDrug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medicaments absents"])},
      "absentEtabAEDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AE établissement absents"])},
      "absentEtabAssociatedDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS établissement absents"])},
      "absentEtabDASDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS établissement absents"])},
      "absentEtabGlobalDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnostics établissement absents"])},
      "absentEtabMMPDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MMP établissement absents"])},
      "absentEtabPrincipalDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DP établissement absents"])},
      "absentEtabRelatedDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC établissement absents"])},
      "absentFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etablissements absents"])},
      "absentMedicalAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitements absents"])},
      "absentMedicalUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités Médicales absentes"])},
      "absentRehabilitationAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitements absents"])},
      "absentSancareAEDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AE prédits absents"])},
      "absentSancareAssociatedDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS prédits absents"])},
      "absentSancareDASDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS prédits absents"])},
      "absentSancareGlobalDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnostics prédits absents"])},
      "absentSancareMMPDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MMP prédits absents"])},
      "absentSancarePrincipalDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DP prédits absents"])},
      "absentSancareRelatedDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC prédits absents"])},
      "absentStartingGhm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GHM absents pré-revalo"])},
      "absentStartingGhmLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sévérités absentes pré-revalo"])},
      "absentStartingGhmType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types de GHM absents pré-revalo"])},
      "medicalActActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité du traitement"])},
      "exclusiveMedicalAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitements exclusifs"])},
      "admissionMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode d'admission"])},
      "biologyResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultat de laboratoire"])},
      "compareDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparaison des DP"])},
      "dependency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépendance"])},
      "depBehaviour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépendance - Comportement"])},
      "depCognitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépendance Cognitive"])},
      "depContinence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépendance - Continence"])},
      "depDressing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépendance - Habillage"])},
      "depFeeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépendance - Alimentation"])},
      "depMoving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépendance - Déplacement et locomotion"])},
      "depPhysical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépendance Physique"])},
      "depSocial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépendance - Communication"])},
      "presentDocumentCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents présents"])},
      "absentDocumentCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents absents"])},
      "fromStayDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du séjour"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
      "hasSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence de séances"])},
      "healthConstant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Constantes"])},
      "medicalUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passage par l'unité"])},
      "moneyGap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écart de tarifs"])},
      "patientAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge du patient"])},
      "presentBothAEDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AE présents"])},
      "presentBothAssociatedDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS présents"])},
      "presentBothDASDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS présents"])},
      "presentBothGlobalDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnostics présents"])},
      "presentBothMMPDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MMP présents"])},
      "presentBothPrincipalDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DP présents"])},
      "presentBothRelatedDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC présents"])},
      "presentContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots-clés présents"])},
      "presentCurrentGhm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GHM présents"])},
      "presentCurrentGhmLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sévérités présentes"])},
      "presentCurrentGhmType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types de racine GHM présents"])},
      "presentInitialGme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GME présents pré-revalo"])},
      "presentCurrentGme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GME présents"])},
      "presentDivision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divisions présentes"])},
      "presentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots-clés dans le titre"])},
      "presentDrug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medicaments présents"])},
      "presentEtabAEDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AE établissement présents"])},
      "presentEtabAssociatedDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS établissement présents"])},
      "presentEtabDASDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS établissement présents"])},
      "presentEtabGlobalDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnostics établissement présents"])},
      "presentEtabMMPDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MMP établissement présents"])},
      "presentEtabPrincipalDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DP établissement présents"])},
      "presentEtabRelatedDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC établissement présents"])},
      "presentFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etablissements présents"])},
      "presentMedicalAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitements présents"])},
      "presentMedicalUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités Médicales présentes"])},
      "presentRehabilitationAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitements présents"])},
      "presentSancareAEDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AE prédits présents"])},
      "presentSancareAssociatedDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS prédits présents"])},
      "presentSancareDASDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS prédits présents"])},
      "presentSancareGlobalDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnostics prédits présents"])},
      "presentSancareMMPDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MMP prédits présents"])},
      "presentSancarePrincipalDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DP prédits présents"])},
      "presentSancareRelatedDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC prédits présents"])},
      "presentStartingGhm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GHM présents pré-revalo"])},
      "presentStartingGhmLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sévérités présentes pré-revalo"])},
      "presentStartingGhmType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types de GHM présents pré-revalo"])},
      "qaAbsentDAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS à suggérer"])},
      "qaAbsentDP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DP à suggérer"])},
      "qaAbsentDR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC à suggérer"])},
      "qaAbsentMedicalAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHOP à suggérer"])},
      "releaseMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de sortie"])},
      "revaloDAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS revalorisants"])},
      "revaloDP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DP revalorisants"])},
      "rumCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de rum"])},
      "softAbsentContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passages exclus des mots-clés"])},
      "startingDmsGap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écart avec la DMS"])},
      "stayDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée du séjour"])},
      "toStayDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du séjour"])}
    }
  },
  "mode": {
    "automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisation"])},
    "new-coding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo-codage"])},
    "optimizable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séjours optimisables"])},
    "quality-stays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle qualité - Optimisation"])}
  },
  "range": {
    "allValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toutes les valeurs"])},
    "greaterOrEqual": {
      "masculine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["supérieur ou égal à ", _interpolate(_named("value")), _interpolate(_named("suffix"))])},
      "feminine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["supérieure ou égale à ", _interpolate(_named("value")), _interpolate(_named("suffix"))])}
    },
    "lessOrEqual": {
      "masculine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["inférieur ou égal à ", _interpolate(_named("value")), _interpolate(_named("suffix"))])},
      "feminine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["inférieure ou égale à ", _interpolate(_named("value")), _interpolate(_named("suffix"))])}
    },
    "equal": {
      "masculine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["égal à ", _interpolate(_named("value")), _interpolate(_named("suffix"))])},
      "feminine": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["égale à ", _interpolate(_named("value")), _interpolate(_named("suffix"))])}
    },
    "rangeBetween": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["de ", _interpolate(_named("lowValue")), " à ", _interpolate(_named("highValue")), _interpolate(_named("suffix"))])}
  },
  "stay": {
    "RSS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FID"])}
  },
  "stayList": {
    "labels": {
      "firstValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECW pré-revalo."])},
      "currentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECW"])}
    }
  },
  "tab": {
    "automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisation"])},
    "new-coding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo-codage"])},
    "quality-stays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle qualité - Optimisation"])}
  }
}