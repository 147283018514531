<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'

import { extractChunksFromSuggestedDiagnosis } from './diagnosis_justifications.js'
import JustificationSummary from './JustificationSummary.vue'


export default {
  components: {
    'justification-summary': JustificationSummary,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    appSettings: { type: Object, required: true },
    type: { type: String, required: true },
    isLoading: { type: Boolean, default: false },
    selected: { type: Boolean, required: true },
    selection: { type: Object, default: null },
    initialDiagnosis: { type: Object, required: true },
    suggestion: { type: Object, default: null },
    healthData: { type: Object, required: true },
    savedSearchIntersectionChunks: { type: Array, default: null },
    displayedRumIdx: { type: Number, required: true },
    rumPredictedLabels: { type: Array, default: null },
    readonly: { type: Boolean, default: false },
  },
  emits: ['make-selection', 'remove-das'],
  data() {
    return {
      showJustificationSummary: false,
    }
  },
  computed: {
    chunks() {
      return extractChunksFromSuggestedDiagnosis(this.suggestedDiagnosis, this.savedSearchIntersectionChunks)
    },
    suggestedDiagnosis() {
      return this.suggestion.diagnoses[0]
    },
    isWildcard() {
      return this.suggestion.isWildcard
    }
  },
  methods: {
    handleHealthDataSelection(mainContent) {
      this.$store.commit('shouldScroll', true)
      this.$store.commit('setMainContent', mainContent)
      if (!this.selected) {
        this.$emit('make-selection', {
          labelType: this.type,
          labelReference: this.suggestedDiagnosis.reference,
          savedSearchIds: _.map(this.suggestedDiagnosis.staySavedSearches, (ss) =>ss.search.id),
        })
      }
      this.showJustificationSummary = !this.showJustificationSummary
    },
    removeDAS() {
      this.$emit('remove-das', this.initialDiagnosis.id)
    },
    getSearchesIds(diagnosis) {
      return _.map(diagnosis.staySavedSearches, (ss) => ss.search.id)
    },
    isSelectedSuggestion(suggestion) {
      return Boolean(
        !_.isNull(this.selection)
          && this.selection.labelType === 'DAS'
          && this.selection.labelReference === suggestion.reference
          && _.isEqual(this.selection.savedSearchIds, _.map(suggestion.staySavedSearches, 'search.id').sort((a, b) => a - b))
      )
    },
  }
}
</script>

<template>
  <div
    :class="{ excluded: initialDiagnosis.excluded }"
    class="row no-gutter align-items-center diagnosis-item"
  >
    <div
      :title="initialDiagnosis.associatedDiagnosis.description"
      class="col d-flex"
    >
      <h2 class="diagnosis-reference">
        {{ initialDiagnosis.reference }}
        <sancare-octicon
          v-if="initialDiagnosis.associatedDiagnosis.description === undefined"
          v-tooltip="'Le diagnostic est inconnu de la CIM-10, il n\'est pas pris en compte dans le groupage'"
          name="alert"
          :width="15"
          :height="15"
        />
      </h2>
      <div
        v-if="'level' in initialDiagnosis.associatedDiagnosis"
        class="diagnosis-level ml-1"
      >
        <span v-if="!initialDiagnosis.isExcluded">Niv.{{ initialDiagnosis.associatedDiagnosis.level }}</span>
        <span v-else>Exclu</span>
      </div>
    </div>
    <div
      v-if="suggestion"
      title="Voir les justifications"
      class="col-auto show-justifications"
      @click.stop="showJustificationSummary = true"
    >
      <v-dropdown
        :shown="showJustificationSummary"
        placement="left"
        class="popover-container"
        popover-class="justification-popover"
        @hide="showJustificationSummary=false"
      >
        <slot />
        <template #popper>
          <justification-summary
            :is-loading="isLoading"
            :chunks="suggestedDiagnosis.canShowRational ? chunks : null"
            :health-data="healthData"
            :saved-searches="suggestedDiagnosis.staySavedSearches"
            :no-value-label="suggestedDiagnosis.hasRational ? 'Justification non disponible en raison des paramètres' : 'Pas de justification'"
            :displayed-rum-idx="displayedRumIdx"
            :rum-predicted-labels="rumPredictedLabels"
            :selection="selection"
            :readonly="readonly"
            @select-health-data="handleHealthDataSelection"
          />
        </template>
      </v-dropdown>
      <div
        v-if="suggestion.diagnoses[0] && suggestion.diagnoses[0].canShowRational && !suggestion.isWildcard && !readonly"
        :class="{ selected: isSelectedSuggestion(suggestion.diagnoses[0]) }"
        class="btn-note icon-block"
      >
        <sancare-octicon
          name="note"
          :width="18"
          :height="18"
        />
      </div>
    </div>
    <div
      v-if="!readonly"
      title="Supprimer le diagnostic"
      class="col-auto predicted-diagnosis-remover"
      @click.stop="removeDAS()"
    >
      <sancare-octicon
        name="x"
        :width="18"
        :height="18"
      />
    </div>
  </div>
</template>
