import _ from 'lodash'

import savedSearchPermissions from './savedSearch'

// This is a copy of the permission graph configured in the backend in security.yaml
// Beware to apply any edit in both files.

export enum RoleEnum {
  ROLE_ADMIN_MANAGER = 'ROLE_ADMIN_MANAGER',
  ROLE_AUTOMATION_MANAGER = 'ROLE_AUTOMATION_MANAGER',
  ROLE_CONNECTOR_MANAGER = 'ROLE_CONNECTOR_MANAGER',
  ROLE_DASHBOARD_ACCESSOR = 'ROLE_DASHBOARD_ACCESSOR',
  ROLE_DOWNLOAD_SOURCEFILE = 'ROLE_DOWNLOAD_SOURCEFILE',
  ROLE_DATA_PROVIDER = 'ROLE_DATA_PROVIDER',
  ROLE_FACILITY_SEARCH_MANAGER = 'ROLE_FACILITY_SEARCH_MANAGER',
  ROLE_MAINTENANCE_VIEWER = 'ROLE_MAINTENANCE_VIEWER',
  ROLE_MONITORING = 'ROLE_MONITORING',
  ROLE_SANCARE_SEARCH_MANAGER = 'ROLE_SANCARE_SEARCH_MANAGER',
  ROLE_SANCARE_SETTING_UPDATER = 'ROLE_SANCARE_SETTING_UPDATER',
  ROLE_SCHEDULE_MANAGER = 'ROLE_SCHEDULE_MANAGER',
  ROLE_SETTING_UPDATER = 'ROLE_SETTING_UPDATER',
  ROLE_STAY_ASSIGNER = 'ROLE_STAY_ASSIGNER',
  ROLE_STAY_BULK_VALIDATOR = 'ROLE_STAY_BULK_VALIDATOR',
  ROLE_STAY_EXPORTER = 'ROLE_STAY_EXPORTER',
  ROLE_STAY_READER = 'ROLE_STAY_READER',
  ROLE_STAY_WRITER = 'ROLE_STAY_WRITER',
  ROLE_SYSTEM_USER_MANAGER = 'ROLE_SYSTEM_USER_MANAGER',
  ROLE_USER_MANAGER = 'ROLE_USER_MANAGER',
  ROLE_USER_VIEWER = 'ROLE_USER_VIEWER',
}

const rolePermissions = {
  ROLE_BOT: [],
  ROLE_DAF: [
    RoleEnum.ROLE_DASHBOARD_ACCESSOR,
  ],
  ROLE_DATA_PROVIDER: [],
  ROLE_DIM: [
    RoleEnum.ROLE_AUTOMATION_MANAGER,
    RoleEnum.ROLE_DASHBOARD_ACCESSOR,
    RoleEnum.ROLE_DOWNLOAD_SOURCEFILE,
    RoleEnum.ROLE_FACILITY_SEARCH_MANAGER,
    RoleEnum.ROLE_SCHEDULE_MANAGER,
    RoleEnum.ROLE_SETTING_UPDATER,
    RoleEnum.ROLE_STAY_ASSIGNER,
    RoleEnum.ROLE_STAY_BULK_VALIDATOR,
    RoleEnum.ROLE_STAY_EXPORTER,
    RoleEnum.ROLE_STAY_READER,
    RoleEnum.ROLE_STAY_WRITER,
    RoleEnum.ROLE_USER_MANAGER,
    RoleEnum.ROLE_USER_VIEWER,
  ],
  ROLE_DIM_IT: [
    RoleEnum.ROLE_DASHBOARD_ACCESSOR,
    RoleEnum.ROLE_SCHEDULE_MANAGER,
    RoleEnum.ROLE_STAY_EXPORTER,
    RoleEnum.ROLE_STAY_READER,
  ],
  ROLE_DSI: [
    RoleEnum.ROLE_DASHBOARD_ACCESSOR,
    RoleEnum.ROLE_USER_MANAGER,
    RoleEnum.ROLE_USER_VIEWER,
  ],
  ROLE_MONITORING: [],
  ROLE_SANCARE_ADMIN: [
    RoleEnum.ROLE_AUTOMATION_MANAGER,
    RoleEnum.ROLE_FACILITY_SEARCH_MANAGER,
    RoleEnum.ROLE_CONNECTOR_MANAGER,
    RoleEnum.ROLE_DASHBOARD_ACCESSOR,
    RoleEnum.ROLE_MAINTENANCE_VIEWER,
    RoleEnum.ROLE_SANCARE_SEARCH_MANAGER,
    RoleEnum.ROLE_SANCARE_SETTING_UPDATER,
    RoleEnum.ROLE_SETTING_UPDATER,
    RoleEnum.ROLE_STAY_READER,
    RoleEnum.ROLE_STAY_WRITER,
    RoleEnum.ROLE_SYSTEM_USER_MANAGER,
    RoleEnum.ROLE_STAY_BULK_VALIDATOR,
    RoleEnum.ROLE_USER_MANAGER,
    RoleEnum.ROLE_USER_VIEWER,
  ],
  ROLE_SANCARE_DEV: [
    RoleEnum.ROLE_ADMIN_MANAGER,
    RoleEnum.ROLE_AUTOMATION_MANAGER,
    RoleEnum.ROLE_CONNECTOR_MANAGER,
    RoleEnum.ROLE_DASHBOARD_ACCESSOR,
    RoleEnum.ROLE_DATA_PROVIDER,
    RoleEnum.ROLE_FACILITY_SEARCH_MANAGER,
    RoleEnum.ROLE_MAINTENANCE_VIEWER,
    RoleEnum.ROLE_MONITORING,
    RoleEnum.ROLE_SANCARE_SEARCH_MANAGER,
    RoleEnum.ROLE_SANCARE_SETTING_UPDATER,
    RoleEnum.ROLE_SCHEDULE_MANAGER,
    RoleEnum.ROLE_SETTING_UPDATER,
    RoleEnum.ROLE_STAY_ASSIGNER,
    RoleEnum.ROLE_STAY_BULK_VALIDATOR,
    RoleEnum.ROLE_STAY_EXPORTER,
    RoleEnum.ROLE_STAY_READER,
    RoleEnum.ROLE_STAY_WRITER,
    RoleEnum.ROLE_SYSTEM_USER_MANAGER,
    RoleEnum.ROLE_USER_MANAGER,
    RoleEnum.ROLE_USER_VIEWER,
  ],
  ROLE_SANCARE_EDITOR: [
    RoleEnum.ROLE_AUTOMATION_MANAGER,
    RoleEnum.ROLE_DASHBOARD_ACCESSOR,
    RoleEnum.ROLE_SANCARE_SEARCH_MANAGER,
    RoleEnum.ROLE_SETTING_UPDATER,
    RoleEnum.ROLE_STAY_READER,
    RoleEnum.ROLE_STAY_WRITER,
  ],
  ROLE_SANCARE_VIEWER: [
    RoleEnum.ROLE_DASHBOARD_ACCESSOR,
    RoleEnum.ROLE_STAY_READER,
  ],
  ROLE_TIM: [
    RoleEnum.ROLE_DASHBOARD_ACCESSOR,
    RoleEnum.ROLE_DOWNLOAD_SOURCEFILE,
    RoleEnum.ROLE_STAY_READER,
    RoleEnum.ROLE_STAY_WRITER,
  ],
  ROLE_TIM_MANAGER: [
    RoleEnum.ROLE_DASHBOARD_ACCESSOR,
    RoleEnum.ROLE_FACILITY_SEARCH_MANAGER,
    RoleEnum.ROLE_SCHEDULE_MANAGER,
    RoleEnum.ROLE_SETTING_UPDATER,
    RoleEnum.ROLE_STAY_ASSIGNER,
    RoleEnum.ROLE_STAY_BULK_VALIDATOR,
    RoleEnum.ROLE_STAY_EXPORTER,
    RoleEnum.ROLE_STAY_READER,
    RoleEnum.ROLE_STAY_WRITER,
    RoleEnum.ROLE_USER_MANAGER,
    RoleEnum.ROLE_USER_VIEWER,
  ],
}

function hasPermission(expectedPermission: RoleEnum, testedRole: string): boolean {
  return _.has(rolePermissions, testedRole) && rolePermissions[testedRole].indexOf(expectedPermission) !== -1
}

export {
  hasPermission,
  savedSearchPermissions,
}
