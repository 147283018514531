import { computed, reactive, ref, UnwrapNestedRefs } from 'vue'

import { Rhs } from '@/store/modules/stay/ssr/types'

export function countRhsPresenceDays(rhs: Rhs): number {
  return rhs.weekDays.split('1').length - 1 + rhs.weekEndDays.split('1').length - 1
}

export function getWeekNbText(rhs: Rhs, withYear = false, short = false): string {
  const prefix = short ? 'S' : 'Sem '
  const suffix = withYear ? `/${rhs.week.year}` : ''

  return `${prefix}${rhs.week.nb}${suffix}`
}

// todo move in frontend-commons
export class CursorHelper<T> {
  private list: UnwrapNestedRefs<T[]>
  private maxDisplayedElements: number
  private cursor = ref(0)

  constructor(list: T[], maxDisplayedElements: number) {
    this.list = reactive(list)
    this.maxDisplayedElements = maxDisplayedElements
  }

  public setCursor(newCursor: number) {
    if (newCursor < 0) {
      this.cursor.value = 0
      return
    }
    if ((newCursor + this.maxDisplayedElements) > this.list.length) {
      this.cursor.value = this.list.length - this.maxDisplayedElements
      return
    }
    this.cursor.value = newCursor
  }
  public moveCursor(step: number) {
    this.setCursor(this.cursor.value + step)
  }

  public displayedElements = computed(() => this.list.slice(this.cursor.value, this.cursor.value + this.maxDisplayedElements))
  public canMove = computed(() => this.maxDisplayedElements < this.list.length)
  public canMoveDown = computed(() => this.cursor.value > 0)
  public canMoveUp = computed(() => (this.cursor.value + this.maxDisplayedElements) < this.list.length)
}
