<script>
import router from '@/router'

export default {
  props: {
    stay: { type: Object, required: true },
  },
  methods: {
    disableAutomation() {
      this.$store.dispatch('disableAutomation', { stayId: this.stay.id })
      this.$store.commit('resetReadingList')
      router.replace({
        query: { mode: 'new-coding' }
      })
    },
  },
}
</script>

<template>
  <div
    v-tooltip.bottom="'Passer en mode manuel'"
    class="btn btn-manual-mode"
    @click="disableAutomation"
  >
    <div class="btn__label">
      Mode manuel
    </div>
  </div>
</template>

<style scoped lang="less">
@import "~@sancare/ui-frontend-commons/src/assets/styles/variables";
@import "@/assets/styles/mco/stay-displayer-actions";

.btn-manual-mode {
  .btn-color(@sancare-light-grey, @sancare-purple)
}
</style>