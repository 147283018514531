import { GroupingError } from '@/store/modules/stay/types'

import { HospitalizationType } from '../types'

export interface Gme {
  reference: string
  description: string
  cm: string
  gn: string
  gr: string
  gl: string
  level: string
}

export interface SsrGroupingResult {
  id?: number | null
  value: number
  gme: Gme
  gmt: string
  groupingErrors?: GroupingError[] | null
}

export const defaultGme = (): Gme => {
  return {
    reference: '',
    description: '',
    cm: '',
    gn: '',
    gr: '',
    gl: '',
    level: ''
  }
}

export const defaultSsrGroupingResult = (): SsrGroupingResult => {
  return {
    id: 0,
    value: 0,
    gme: defaultGme(),
    gmt: '',
    groupingErrors: []
  }
}

export interface AsideStayGmeProps {
  hospitalizationType: HospitalizationType
  groupingResult: SsrGroupingResult
  moneyGap?: number
}

export interface AsideRhsGmeProps extends AsideStayGmeProps {
  isRefreshing: boolean
  stayGroupingResult: SsrGroupingResult
}
