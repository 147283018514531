<script>
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'

import FindBar from '@/common/FindBar.vue'
import PmsiTypeSelector from '@/common/PmsiTypeSelector.vue'
import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'
import router from '@/router'
import { ModeEnum, PmsiTypeEnum } from '@/store/modules/settings/types'

export default {
  components: { FindBar, PmsiTypeSelector },
  data() {
    return {
      stayIdInput: this.$store.state.route.params.stayId,
      displaySearch: false,
      enterPressed: false,
      searchOngoing: false,
      RoleEnum,
      PmsiTypeEnum: PmsiTypeEnum
    }
  },
  computed: {
    ...mapGetters(['settingsUrlQuery', 'pmsiType']),
    ...mapState({
      isSsrEnabled: (state) => state.settings.sancareSettings.boolSettings.enable_ssr.value,
      stayId: (state) => state.route.params.stayId,
      searchStayRequest: (state) => state.stayDisplayer.searchStayRequest,
      searchStayResults: (state) => state.stayDisplayer.searchStayResults,
      userRole: (state) => state.login.userRole,
      selectedMedicalUnits: (state) => state.settings.selectedMedicalUnits,
      homeLink: function(state) {
        if (state.login.userRole === 'ROLE_DATA_PROVIDER') {
          return { path: '/manage-api-key' }
        }

        return {
          name: 'home',
          params: { pmsiType: this.pmsiType },
          query: {
            mode: state.route.query.mode ?? ModeEnum.OPTIMIZABLE,
            cu: sessionStorage.getItem('stats-unit') ?? 'month',
            ...this.settingsUrlQuery,
          }
        }
      },
    })
  },
  watch: {
    stayIdInput(newStayIdInput) {
      this.searchOngoing = true
      this.throttleStayIdInput(newStayIdInput)
    }
  },
  methods: {
    handleSearch({ result, input }) {
      const stayIdInput = !_.isEmpty(result) ? result.id : input
      const query = _.isEmpty(result) ? {} : { mode: result.hasInitialCoding ? ModeEnum.OPTIMIZABLE : ModeEnum.NEW_CODING }

      router.push({
        path: `/id/${stayIdInput}`,
        // FIXME there should be a way to jump to automation mode?
        query: query,
      })
    },
    hasPermission,
  },
}
</script>

<template>
  <div class="search-bar">
    <div class="container">
      <div class="row align-items-center">
        <router-link
          name="stayFinder_home"
          :to="homeLink"
          class="logo row no-gutters align-items-center"
        >
          <div class="col-auto">
            <img
              alt="Logo Sancare"
              src="../assets/sancare_logo.png"
            >
          </div>
          <div class="col-auto pl-3">
            <div class="logo-title">
              SANCARE
            </div>
            <div class="logo-subtitle">
              HEALTH &amp; DATA
            </div>
          </div>
        </router-link>
        <pmsi-type-selector
          v-if="isSsrEnabled"
        />
        <div
          v-if="hasPermission(RoleEnum.ROLE_STAY_READER, userRole)"
          class="col-8"
        >
          <find-bar
            :default-search-value="stayId"
            class="w-100"
            @handle-submit="handleSearch"
            @handle-click="handleSearch"
          />
        </div>
      </div>
    </div>
  </div>
</template>
