import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import _ from 'lodash'
import { toRaw } from 'vue'

import { buildRssDisplay } from '@/stay-displayer/rssRumBuilder'
import { McoStay, RawMcoStay } from '@/store/modules/stay/mco/types'
import { RawSsrStay, SsrStay } from '@/store/modules/stay/ssr/types'
import { RawStay } from '@/store/modules/stay/types'
import { getKeywordList } from '@/text-processing/utils'

let runningWorker = null

export function removeSearchDuplicata(stay: RawStay): RawStay {
  const privateSearchReference = stay.staySavedSearches.filter((ss) => ss.search.privacy === 'privateSearch').map((ss) => ss.search.reference)
  const staySavedSearches = []

  stay.staySavedSearches.forEach((ss) => {
    if (ss.search.privacy === 'privateSearch') {
      staySavedSearches.push(ss)
    } else if (privateSearchReference.indexOf(ss.search.reference) === -1) {
      staySavedSearches.push(ss)
    }
  })

  return { ...stay, staySavedSearches: staySavedSearches }
}

// This is a recursive function that returns the same object,
// but where diagnoses (object that have a key named "diagnosis") are changed
// in order to include the level of the current stay.
const parseDiagnosisLabels = (stayEnd, obj) => {
  if (_.isArray(obj)) {
    return _.map(obj, parseDiagnosisLabels.bind(this, stayEnd))
  }
  if (_.isObjectLike(obj)) {
    return _.mapValues(obj, (value, key) => {
      if (!value || !key) {
        return value
      }
      if (_.includes(key.toLowerCase(), 'diag') && _.isArray(value) && _.every(_.map(value, (itm) => _.isArray(itm.diagnosisVersions)))) {
        return _.map(value, (subvalue) => {
          const correctVersion = _.find(subvalue.diagnosisVersions, (ver) => dayjs(ver.startDate).isSameOrBefore(stayEnd) && dayjs(ver.endDate).isAfter(stayEnd))
          return {
            ...subvalue,
            level: correctVersion ? correctVersion.level : 1
          }
        })
      }
      if (_.includes(key.toLowerCase(), 'diag') && _.isArray(value.diagnosisVersions)) {
        const correctVersion = _.find(value.diagnosisVersions, (ver) => dayjs(ver.startDate).isSameOrBefore(stayEnd) && dayjs(ver.endDate).isAfter(stayEnd))
        return {
          ...value,
          level: correctVersion ? correctVersion.level : 1
        }
      }
      return parseDiagnosisLabels(stayEnd, value)
    })
  }
  return obj
}

function isRawMcoStay(stay: RawStay): stay is RawMcoStay {
  return 'rums' in stay
}

export function simpleParseStay(stay: RawMcoStay): McoStay
export function simpleParseStay(stay: RawSsrStay): SsrStay
export function simpleParseStay(stay: RawStay): McoStay | SsrStay {
  const stayEnd = dayjs(stay.stayEnd)
  const staySavedSearches = stay.staySavedSearches.filter((s) => !s.search.disabled)
  const parsedStay = {
    ...stay,
    patient: {
      ...stay.patient,
      birthDate: stay.patient.birthDate ? dayjs(stay.patient.birthDate) : null,
    },
    lastPredictionUpdate: stay.lastPredictionUpdate ? dayjs(stay.lastPredictionUpdate) : null,
    lastHealthDataUpdate: stay.lastHealthDataUpdate ? dayjs(stay.lastHealthDataUpdate) : null,
    lastExternalPmsiUpdate: stay.lastExternalPmsiUpdate ? dayjs(stay.lastExternalPmsiUpdate) : null,
    stayStart: dayjs(stay.stayStart),
    stayEnd,
    reports: _.map(stay.reports, (report) => ({
      ...report,
      creationDate: report.creationDate ? dayjs(report.creationDate) : null,
    })),
    categoricalLabResults: _.map(stay.categoricalLabResults, (res) => ({
      ...res,
      creationDate: res.creationDate ? dayjs(res.creationDate) : null,
    })),
    labResults: _.map(stay.labResults, (res) => ({
      ...res,
      creationDate: res.creationDate ? dayjs(res.creationDate) : null,
    })),
    drugEvents: _.map(stay.drugEvents, (event) => ({
      ...event,
      creationDate: event.creationDate ? dayjs(event.creationDate) : null,
    })),
    textualHealthEntries: _.map(stay.textualHealthEntries, (entry) => ({
      ...entry,
      creationDate: entry.creationDate ? dayjs(entry.creationDate) : null,
    })),
    healthConstants: _.map(stay.healthConstants, (cst) => ({
      ...cst,
      creationDate: cst.creationDate ? dayjs(cst.creationDate) : null,
    })),
    staySavedSearches: _.map(staySavedSearches, (staySavedSearch) => ({
      ...parseDiagnosisLabels(stayEnd, staySavedSearch),
    })),
    savedSearchIntersectionChunks: stay.savedSearchIntersectionChunks || [],
    additionnalKeywordSearches: stay.additionnalKeywordSearches || [],
    additionnalKeywordChunks: stay.additionnalKeywordChunks || {},
    isLoading: ('isLoading' in stay) ? stay.isLoading : true,
  }

  if (isRawMcoStay(stay)) {
    const parsedRums = _.map(stay.rums, (rum) => ({
      ...parseDiagnosisLabels(stayEnd, rum),
      rumStart: dayjs(rum.rumStart),
      rumEnd: dayjs(rum.rumEnd),
    }))
    if (parsedRums.length > 1) {
      parsedRums.push(buildRssDisplay(stay.rums))
    }

    return {
      ...parsedStay,
      suggestedChronicDas: stay.suggestedChronicDas,
      rums: parsedRums
    } as McoStay
  }

  return parsedStay as SsrStay
}

export function asyncStayParsing(stay: RawStay, currentSearch, settings) {
  if (runningWorker !== null) {
    runningWorker.terminate()
  }
  const promise = new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    runningWorker = new Worker(new URL('../text-worker.js', import.meta.url))

    runningWorker.onmessage = (e) => {
      resolve(simpleParseStay({ ...e.data, isLoading: false }))
      runningWorker.terminate()
      runningWorker = null
    }
    runningWorker.onerror = (e) => {
      runningWorker.terminate()
      runningWorker = null
      // eslint-disable-next-line no-console
      console.error(e)
      reject()
    }

    // toRaw is used because only simple objects can be parsed by worker postMessage
    const rawSettings = toRaw(settings)
    runningWorker.postMessage({
      stay: removeSearchDuplicata(stay),
      keywordParam: getKeywordList(currentSearch?.criteriaGroups ?? []),
      settings: rawSettings
    })
  })

  // caution: sometimes text-worker crash silently. We must catch manually the error in order to show it.
  promise.catch((err) => {
    // eslint-disable-next-line
    console.error(err)
  })

  return promise
}
