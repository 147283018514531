function computeSvgLink(startHeight, endHeight, width, invertDirection = false) {
  let alpha
  let radiusH
  let radiusL
  let radiusW


  if (invertDirection === true) {
    alpha = Math.atan2(startHeight - endHeight, width / 2)
    radiusL = 7 * Math.tan(alpha / 2)
    radiusH = radiusL * Math.sin(alpha)
    radiusW = radiusL * Math.cos(alpha)
  } else {
    alpha = Math.atan2(endHeight - startHeight, width / 2)
    radiusL = 7 * Math.tan(alpha / 2)
    radiusH = radiusL * Math.sin(-alpha)
    radiusW = radiusL * Math.cos(alpha)
  }

  return {
    path: `M10 ${startHeight}
          ${width / 4 - radiusL} ${startHeight}
          Q ${width / 4} ${startHeight} ${width / 4 + radiusW} ${startHeight - radiusH}
          L ${width * 3 / 4 - radiusW} ${endHeight + radiusH}
          Q ${width * 3 / 4} ${endHeight} ${width * 3 / 4 + radiusL} ${endHeight}
          L ${width - 10} ${endHeight}`,
    startHeight: startHeight,
    endHeight: endHeight,
  }
}

export {
  computeSvgLink
}
