<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { mapState } from 'vuex'

import LoadingMark from '@/misc/LoadingMark.vue'
import { StayStatusEnum } from '@/store/modules/stay/types'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
    'loading-mark': LoadingMark,
  },
  props: {
    stay: { type: Object, required: true },
    mode: { type: String, required: true },
  },
  data() { 
    return { 
      isFetching: false,
      StayStatusEnum
    }
  },
  computed: {
    ...mapState({
      updateStayStatusRequest: (state) => state.stayDisplayer.updateStayStatusRequest,
    }),
    isStayReviewed() {
      return this.stay.status === StayStatusEnum.REVIEW
    },
    buttonLabel() {
      return this.isStayReviewed ? 'Retirer le statut "À revoir"' : 'Ajouter le statut "À revoir"'
    }
  },
  methods: {
    async reviewStay() {
      if (this.updateStayStatusRequest.fetching) {
        return
      }
      this.isFetching = true
      await this.$store.dispatch(
        'updateStayStatus',
        {
          stayId: this.stay.id,
          status: 'review',
          mode:  this.mode,
        }
      )
      this.isFetching = false
    },
    async unReviewStay() {
      if (this.updateStayStatusRequest.fetching) {
        return
      }
      this.isFetching = true
      await this.$store.dispatch(
        'updateStayStatus',
        {
          stayId: this.stay.id,
          status: 'unvalidated',
          mode: this.mode,
        }
      )
      this.isFetching = false
    }
  }
}
</script>

<template>
  <div>
    <div
      v-tooltip.bottom="buttonLabel"
      :class="{
        loading: isFetching,
        active: isStayReviewed,
      }"
      class="btn btn-icon__action"
      @click="isStayReviewed ? unReviewStay() : reviewStay()"
    >
      <sancare-octicon
        v-if="!isFetching"
        :name="isStayReviewed ? 'bookmark-fill' : 'bookmark'"
        :width="16"
        :height="16"
      />
      <loading-mark
        v-else
        :finished="!isFetching"
        :color="isStayReviewed ? 'grey' : 'blue'"
        style="width: 16px; height: 16px"
      />
    </div>
  </div>
</template>