<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { mapState } from 'vuex'

import LoadingMark from '@/misc/LoadingMark.vue'
import { StayStatusEnum } from '@/store/modules/stay/types'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
    'loading-mark': LoadingMark,
  },
  props: {
    stay: { type: Object, required: true },
    searches: { type: Array, required: true },
    mode: { type: String, required: true },
  },
  data() {
    return {
      isFetching: false,
      StayStatusEnum
    }
  },
  computed: {
    ...mapState({
      updateStayStatusRequest: (state) => state.stayDisplayer.updateStayStatusRequest,
    }),
    buttonLabel() {
      return this.stay.status === 'validated' ? 'Annuler la validation' : 'Valider et synchroniser'
    },
    validationStateClass() {
      return `btn-grouping-validation__${this.stay.status === StayStatusEnum.VALIDATED ? 'validated' : 'unvalidated'}`
    }
  },
  methods: {
    async validateStay() {
      if (this.updateStayStatusRequest.fetching) {
        return
      }
      this.isFetching = true
      await this.$store.dispatch(
        'updateStayStatus',
        {
          stayId: this.stay.id,
          status: 'validated',
          mode:  this.mode,
        }
      )
      this.isFetching = false
    },
    async unvalidateStay() {
      if (this.updateStayStatusRequest.fetching) {
        return
      }
      this.isFetching = true
      await this.$store.dispatch(
        'updateStayStatus',
        {
          stayId: this.stay.id,
          status: 'unvalidated',
          mode: this.mode,
        }
      )
      this.isFetching = false
    },
  }
}
</script>

<template>
  <div
    v-tooltip.bottom="'Valider et synchroniser le séjour'"
    :class="{
      'loading': isFetching,
      [validationStateClass]: true
    }"
    class="btn btn-grouping-validation"
    @click="stay.status === 'validated' ? unvalidateStay() : validateStay()"
  >
    <div
      class="btn__label"
      :class="{ 'btn-loading__label': isFetching }"
    >
      {{ buttonLabel }}
    </div>
    <div
      :class="isFetching ? 'btn-loading__icon' : 'btn__icon'"
    >
      <sancare-octicon
        v-if="!isFetching"
        :name="stay.status === StayStatusEnum.VALIDATED ? 'x' : 'arrow-right'"
      />
      <loading-mark
        v-else
        :finished="!isFetching"
        color="blue"
        class="btn-loading"
        style="width: 16px; height: 16px"
      />
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "~@sancare/ui-frontend-commons/src/assets/styles/variables";
@import "@/assets/styles/mco/stay-displayer-actions";

.btn-grouping-validation {
  &__unvalidated {
    .btn-color(@sancare-green, #fff)
  }

  &__validated {
    .btn-color(@sancare-light-grey, @sancare-purple)
  }

  .btn__label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>