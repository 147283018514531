<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import { CountryEnum } from '../store/modules/settings/types'
import MultiSelectedItemList from './diagnosis-lists/standard-mode/MultiSelectedItemsList.vue'
import SingleSelectedItemList from './diagnosis-lists/standard-mode/SingleSelectedItemList.vue'

export default {
  components: {
    'single-selected-item-list': SingleSelectedItemList,
    'multi-selected-item-list': MultiSelectedItemList,
  },
  props: {
    sancareSettings: { type: Object, required: true },
    appSettings: { type: Object, required: true },
    mode: { type: String, required: true },
    ghm: { type: Object, default: null },
    rumId: { type: Number, required: true },
    rumPredictedLabels: { type: Array, required: true },
    suggestedChronicDas: { type: Object, required: true },
    standardModeDiagnosisGroup: { type: Object, required: true },
    automationModeDiagnosisGroup: { type: Object, default: () => ({}) },
    selection: { type: Object, default: null },
    isLoading: { type: Boolean, default: false },
    healthData: { type: Object, required: true },
    savedSearchIntersectionChunks: { type: Array, default: null },
    staySavedSearches: { type: Array, default: null },
    standardModeExcludedDiagnoses: { type: Array, required: true },
    displayedRumIdx: { type: Number, required: true },
    stayHasInitialCoding: { type: Boolean, required: true }
  },
  emits: ['make-selection'],
  data() {
    return {
      CountryEnum,
    }
  },
  computed: {
    suggestedPrincipalDiagnoses() {
      return this.buildSuggestedDiags('DP')
    },
    suggestedRelatedDiagnoses() {
      return this.buildSuggestedDiags('DR')
    },
    suggestedAssociatedDiagnoses() {
      return this.buildSuggestedDiags('DAS')
    },
    ...mapState({
      pmsiCountry: (state) => state.settings.pmsi.country,
    }),
  },
  methods: {
    buildSuggestedDiags(type) {
      const suggestedDiags = []

      let predictedDiagnoses = []
      predictedDiagnoses = _.filter(this.rumPredictedLabels, (d) => d.type === type)

      // if "don't show already coded suggested DAS" is set
      if(type === 'DAS' && !this.appSettings.optimizable_stays_show_coded_das_found_by_ai){
        predictedDiagnoses = _.filter(predictedDiagnoses, (d) => !_.some(this.standardModeDiagnosisGroup.associatedDiags, (diag) => !diag.associatedDiagnosis.disabled && d.diagnosis.reference === diag.associatedDiagnosis.reference))
      }

      // First we prepare our diagnoses reference List
      const staySavedSearches = _.filter(this.staySavedSearches, (ss) => {
        return _.some(ss.search.globalCriteriaGroup, (criteriaList) => {
          return _.some(criteriaList, (criterion) => criterion.type === `revalo${type}` || criterion.type === `qaAbsent${type}`)
        })
      })

      let searchCriteria = []
      _.forEach(staySavedSearches, (ss) => {
        _.forEach(ss.search.globalCriteriaGroup, (criteriaList) => {
          const currentSearchCriteria = _.filter(criteriaList, (criterion) => criterion.type === `revalo${type}` || criterion.type === `qaAbsent${type}`)
          searchCriteria = _.concat(searchCriteria, currentSearchCriteria)
        })
      })

      // get all suggested and predicted diagnosis references
      const searchCriteriaDiags = _.flatten(_.map(searchCriteria, (f) => f.value))
      const predictedDiagRefs = _.flatten(_.map(predictedDiagnoses, (f) => f.diagnosis.reference))
      const diagnosesList = _.uniq([...searchCriteriaDiags, ...predictedDiagRefs])

      // remove references which match wildcards (and keep wildcards)
      const searchedWildcardedDiags = _.filter(searchCriteriaDiags, (d) => d.indexOf('*') !== -1)
      _.forEach(searchedWildcardedDiags, (wildRef) => {
        const refRegex = wildRef.replace('*', '.*').replace(/\+/g, '\\+')
        _.remove(diagnosesList, (diagRef) => {
          return diagRef.match(refRegex) && diagRef !== wildRef
        })
      })

      // Now we can build diagnoses suggestion
      _.forEach(diagnosesList, (suggestedDiag) => {
        // First we prepare our suggestion
        let suggestion = {
          reference: suggestedDiag,
          description: null,
          isWildcard: suggestedDiag.indexOf('*') !== -1,
          level: { min: null, max: null, label: null },
          confidence: { min: null, max: null, label: null },
          diagnoses: [],
          searches: [],
          predictions: [],
        }
        let suggestionRegex = `^${suggestedDiag.replace(/\+/g, '\\+')}$`
        if (suggestedDiag.indexOf('*') === suggestedDiag.length - 1) {
          suggestionRegex = `^${suggestedDiag.slice(0, -1).replace(/\+/g, '\\+')}`
        }

        // We fill it with the matching searches
        _.forEach(staySavedSearches, (ss) => {
          const diags = ss.search.suggestedDiagnoses.filter((suggestedDiag) => suggestedDiag.type === type).map((suggestedDiag) => suggestedDiag.diagnosis)
          const matchingDiags = _.filter(diags, (d) => d.reference.match(suggestionRegex))
          if (matchingDiags.length) {
            suggestion.searches.push(ss)
            _.forEach(matchingDiags, (diag) => {
              const existAtIndex = _.findIndex(suggestion.diagnoses, (d) => d.reference === diag.reference)
              if (existAtIndex === -1) {
                suggestion.diagnoses.push({
                  reference: diag.reference,
                  level: diag.level,
                  versions: diag.diagnosisVersions,
                  description: diag.description,
                  excluded: this.standardModeExcludedDiagnoses.indexOf(diag.reference) !== -1,
                  canShowRational: true,
                  staySavedSearches: [ss],
                  prediction: null,
                })
              } else {
                suggestion.diagnoses[existAtIndex].staySavedSearches.push(ss)
              }

              if (diag.level < suggestion.level.min || suggestion.level.min === null) {
                suggestion.level.min = diag.level
              }
              if (diag.level > suggestion.level.max || suggestion.level.max === null) {
                suggestion.level.max = diag.level
              }
            })
          }
        })

        // We add the matching IA predictions
        _.forEach(predictedDiagnoses, (prediction) => {
          const diag = prediction.diagnosis
          if (!diag.reference.match(suggestionRegex)) {
            return 
          }

          const hasRational = (prediction.hints && prediction.hints.length)
          const canShowRational = hasRational && _.some(prediction.hints, (h) => h.confidence >= this.appSettings['standard_mode_hint_threshold'])
          const excluded = this.standardModeExcludedDiagnoses.indexOf(diag.reference) !== -1

          const existAtIndex = _.findIndex(suggestion.diagnoses, (d) => d.reference === diag.reference)

          if (existAtIndex === -1) {
            suggestion.predictions.push(prediction)
            suggestion.diagnoses.push({
              reference: diag.reference,
              level: diag.level,
              versions: diag.diagnosisVersions,
              description: diag.description,
              excluded: excluded,
              hasRational: hasRational,
              canShowRational: canShowRational,
              prediction: prediction,
              staySavedSearches: [],
            })
          } else  {
            suggestion.predictions.push(prediction)
            suggestion.diagnoses[existAtIndex].prediction = prediction
            suggestion.hasRational = suggestion.hasRational || hasRational
            suggestion.canShowRational = suggestion.canShowRational || canShowRational
          }

          // We update some informations about this suggestion
          if (diag.level < suggestion.level.min || suggestion.level.min === null) {
            suggestion.level.min = diag.level
          }
          if (diag.level > suggestion.level.max || suggestion.level.max === null) {
            suggestion.level.max = diag.level
          }
          if (prediction.confidence < suggestion.confidence.min || suggestion.confidence.min === null) {
            suggestion.confidence.min = prediction.confidence
          }
          if (prediction.confidence > suggestion.confidence || suggestion.confidence.max === null) {
            suggestion.confidence.max = prediction.confidence
          }
        })


        // We finalize our Suggestion
        if (suggestion.isWildcard && suggestion.diagnoses.length > 1) {
          suggestion.description = `Diagnostic commençant par ${suggestion.reference.replace('*', '')}`
        } else if (suggestion.diagnoses.length === 1) {
          suggestion.description = _.first(suggestion.diagnoses).description
        }

        if (suggestion.level.min || suggestion.level.max) {
          if (suggestion.level.min === suggestion.level.max) {
            suggestion.level.label = suggestion.level.min
          } else {
            suggestion.level.label = `${suggestion.level.min} - ${suggestion.level.max}`
          }
        }

        if (suggestion.confidence.min || suggestion.confidence.max) {
          if (suggestion.confidence.min === suggestion.confidence.max) {
            suggestion.confidence.label = `${Math.round(suggestion.confidence.min * 100)}%`
          } else {
            suggestion.confidence.label = `${Math.round(suggestion.confidence.min * 100)}% - ${Math.round(suggestion.confidence.max * 100)}%`
          }
        }

        let isInitialDiag = false
        let isCurrentDiag = false
        let isRevaluating = true
        let isExcluded = false
        let isLowLevel = false
        let isOverThreshold = true
        if (type === 'DP' || type === 'DR') {
          isInitialDiag = this.standardModeDiagnosisGroup[`first${type}`] && this.standardModeDiagnosisGroup[`first${type}`].reference.match(suggestionRegex) !== null
          isCurrentDiag = this.standardModeDiagnosisGroup[`current${type}`] && this.standardModeDiagnosisGroup[`current${type}`].reference.match(suggestionRegex) !== null
          if (type === 'DP') {
            isOverThreshold = suggestion.confidence.max !== null && suggestion.confidence.max >= this.appSettings['standard_mode_dp_threshold']
          }
        } else {
          isInitialDiag = _.some(
            this.standardModeDiagnosisGroup.associatedDiags,
            (associatedDiag) => (
              associatedDiag.associatedDiagnosis.reference === suggestedDiag
              && ['external', 'unsure', 'prediction_unsure'].indexOf(associatedDiag.codingOrigin) !== -1
            )
          )
          isCurrentDiag = _.some(
            this.standardModeDiagnosisGroup.associatedDiags,
            (associatedDiag) => (
              associatedDiag.associatedDiagnosis.reference === suggestedDiag
              && !associatedDiag.disabled
            )
          )
          isRevaluating = (
            this.ghm && !_.isNaN(parseInt(this.ghm.level)) && suggestion.level.max > parseInt(this.ghm.level)
          )
          isExcluded = _.every(suggestion.diagnoses, (d) => d.excluded)
          isLowLevel = suggestion.level.max === 1
          isOverThreshold = suggestion.confidence.max !== null && suggestion.confidence.max >= this.appSettings[this.stayHasInitialCoding ? 'standard_mode_das_threshold' : 'new_coding_das_threshold']
        }

        const showLowLevelOrExcluded = this.appSettings[!this.stayHasInitialCoding ? 'new_coding_stays_show_low_das' : 'optimizable_stays_show_low_das']

        suggestion = {
          ...suggestion,
          isExcluded: isExcluded,
          isInitialDiag: isInitialDiag,
          isCurrentDiag: isCurrentDiag,
          isRevaluating: isRevaluating,
          isOverThreshold: isOverThreshold,
          isLowLevel: isLowLevel,
          isAlwaysVisible: false,
          isAlwaysHidden: (!isInitialDiag && (
            isCurrentDiag
            || ((isExcluded || isLowLevel) && !showLowLevelOrExcluded)
          )),
        }
        // Finally we add our suggestion to the list
        suggestedDiags.push(suggestion)
      })

      const betterSuggestedDiag = _.head(_.orderBy(suggestedDiags, ['isInitialDiag', 'searches.length', 'level', 'predictions.confidence'], ['desc', 'desc', 'desc', 'desc']))
      _.forEach(suggestedDiags, (diag, idx) => {
        suggestedDiags[idx] = {
          ...diag,
          isAlwaysVisible: (
            diag.isInitialDiag // Suggested diagnoses that are also initial are always displayed
            || (diag.searches.length > 0 && !diag.isCurrentDiag && !diag.isExcluded) // Non-excluded searches are always displayed
            || (!diag.isExcluded && !diag.isCurrentDiag && diag.isRevaluating && diag.isOverThreshold) // IA diagnoses are shown if they are revaluating and over the threshold
            || (this.stayHasInitialCoding === false && diag.isOverThreshold)) // If it is above new coding das threshold, it may be displayed for stays without initial coding
            || (this.stayHasInitialCoding === false && betterSuggestedDiag.reference === diag.reference) // Better suggested diag must always be shown
        }
      })

      return suggestedDiags
    },
    showRelatedDiags() {
      // FIXME - Sometimes automation mode diagnosis group is not set
      return this.pmsiCountry === CountryEnum.CH || _.get(this, 'standardModeDiagnosisGroup.currentDR') || _.get(this, 'automationModeDiagnosisGroup.currentDR')
        || (this.standardModeDiagnosisGroup && this.standardModeDiagnosisGroup.currentDP && this.standardModeDiagnosisGroup.currentDP.reference[0] === 'Z')
        || (this.automationModeDiagnosisGroup && this.automationModeDiagnosisGroup.currentDP && this.automationModeDiagnosisGroup.currentDP.reference[0] === 'Z')
    }
  },
}
</script>

<template>
  <div class="card-container">
    <single-selected-item-list
      :app-settings="appSettings"
      :rum-id="rumId"
      :displayed-rum-idx="displayedRumIdx"
      :rum-predicted-labels="rumPredictedLabels"
      :standard-mode-diagnosis-group="standardModeDiagnosisGroup"
      :suggested-diagnoses="suggestedPrincipalDiagnoses"
      :is-loading="isLoading"
      :selection="selection"
      :health-data="healthData"
      :saved-search-intersection-chunks="savedSearchIntersectionChunks"
      :mode="mode"
      type="DP"
      class="principal-diagnosis-group"
      @make-selection="(selection) => $emit('make-selection', selection)"
    />
    <single-selected-item-list
      v-if="showRelatedDiags()"
      :app-settings="appSettings"
      :rum-id="rumId"
      :displayed-rum-idx="displayedRumIdx"
      :rum-predicted-labels="rumPredictedLabels"
      :standard-mode-diagnosis-group="standardModeDiagnosisGroup"
      :suggested-diagnoses="suggestedRelatedDiagnoses"
      :is-loading="isLoading"
      :selection="selection"
      :health-data="healthData"
      :saved-search-intersection-chunks="savedSearchIntersectionChunks"
      :mode="mode"
      type="DR"
      class="related-diagnosis-group"
      @make-selection="(selection) => $emit('make-selection', selection)"
    />
    <multi-selected-item-list
      :app-settings="appSettings"
      :sancare-settings="sancareSettings"
      :ghm="ghm"
      :rum-id="rumId"
      :displayed-rum-idx="displayedRumIdx"
      :rum-predicted-labels="rumPredictedLabels"
      :standard-mode-diagnosis-group="standardModeDiagnosisGroup"
      :suggested-chronic-das="suggestedChronicDas"
      :suggested-diagnoses="suggestedAssociatedDiagnoses"
      :selection="selection"
      :is-loading="isLoading"
      :health-data="healthData"
      :saved-search-intersection-chunks="savedSearchIntersectionChunks"
      :stay-saved-searches="staySavedSearches"
      :standard-mode-excluded-diagnoses="standardModeExcludedDiagnoses"
      :mode="mode"
      class="associated-diagnosis-group"
      type="DAS"
      @make-selection="(suggestion) => $emit('make-selection', suggestion)"
    />
  </div>
</template>
