<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'

import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'

import { documentCategories, textualHealthDataCategories } from '../stay-displayer/health-data/documentLabels.js'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
  },
  props: {
    filterData: { type: Object, required: true },
    isGlobalCondition : { type: Boolean, default: false },
    isLegacy: { type: Boolean, default: true },
  },
  emits: ['add-filter'],
  data() {
    return {
      presence: this.isGlobalCondition ? 'softAbsent' : 'present',
      extendedSearch: false,
      includeAntecedents: false,
      includeFamilyAntecedents: false,
      hardAbsentContent: false,
      specifyWordDistance: false,
      specifyDocumentCategory: false,
      value: '',
      wordDistance: 0,
      documentCategory: null,
      textualReportCategories: { ...documentCategories, ...textualHealthDataCategories },
    }
  },
  computed: {
    isSearchManager() {
      return hasPermission(RoleEnum.ROLE_SANCARE_SEARCH_MANAGER, this.$store.state.settings.currentUser.role)
    },
    filterOptions() {
      const subOpt = this.filterData.subFilters[0]
      const isGlobalOptions = subOpt.isGlobalOptions ? subOpt.isGlobalOptions : []
      const notGlobalOptions = subOpt.notGlobalOptions ? subOpt.notGlobalOptions : subOpt.options

      return _.intersection(
        subOpt.options,
        this.isGlobalCondition ? isGlobalOptions : notGlobalOptions
      )
    },
  },
  watch: {
    presence() {
      this.resetModifiers()
    },
    hardAbsentContent(value) {
      if (!value) {
        this.resetModifiers()
      }
    },
  },
  methods: {
    resetModifiers() {
      this.includeAntecedents = false
      this.includeFamilyAntecedents = false
      this.hardAbsentContent = this.isLegacy ? false : !this.isGlobalCondition
      this.specifyWordDistance = false
      this.specifyDocumentCategory = false
      this.wordDistance = 0
      this.documentCategory = null
    },
    addFilter() {
      let filterValue = this.value.trim()
      if (!filterValue)
        return 
      this.hardAbsentContent = this.isLegacy ? this.hardAbsentContent : !this.isGlobalCondition
      const modifiers = []
      if (this.includeAntecedents && (this.presence === 'present' || this.hardAbsentContent)) {
        modifiers.push('a')
      }
      if (this.includeFamilyAntecedents && (this.presence === 'present' || this.hardAbsentContent)) {
        modifiers.push('f')
      }
      if (this.specifyWordDistance) {
        modifiers.push(`wd${this.wordDistance}`)
      }

      // We build filterValue. If there are modifiers, they are put before a "Unit separator" (0x1F) character
      if (modifiers.length && (this.presence === 'present' || this.hardAbsentContent)) {
        filterValue = `${modifiers.join('_')}\x1F${this.value}`
      }

      const presence = this.presence === 'absent' && !this.hardAbsentContent ? 'softAbsent' : this.presence
      let type = `${presence}Content`
      if (this.specifyDocumentCategory) {
        type += `__${this.documentCategory}`
      }

      this.$emit('add-filter', {
        name: this.filterData.name,
        type: type,
        value: filterValue,
      })
    },
  },
}
</script>

<template>
  <div
    class="row"
    :class="isLegacy ? [] : ['px-3']"
  >
    <v-dropdown
      v-if="isLegacy"
      :triggers="['hover']"
      placement="top"
      popover-class="criteria-popover"
    >
      <span class="saved-search-icon">
        <sancare-octicon
          name="question"
          :width="16"
          :height="30"
        />
      </span>
      <slot />
      <template #popper>
        <div style="width: 30rem;">
          <div>Mots-clés présents : Recherche des mots-clés dans les documents</div>
          <hr>
          <div>
            <div>Passages exclus : Permet de retirer un ou des passages de la recherche par mot-clé</div>
            <div class="ml-2">
              <small>Il est possible de passer ce filtre en mode exclusion stricte (pour enlever de la recherche les séjours contenant les mots-clés)</small>
            </div>
          </div>
        </div>
      </template>
    </v-dropdown>

    <div
      v-if="isLegacy"
      class="col-10 input-group"
    >
      <select
        v-model="presence"
        name="textContentCriteria_presence"
        class="custom-select col-4"
      >
        <option
          v-for="opt in filterData.subFilters[0].notGlobalOptions"
          :key="opt"
          :value="opt"
        >
          {{ filterData.subFilters[0].labels[opt] }}
        </option>
      </select>
      <select
        v-if="specifyDocumentCategory"
        v-model="documentCategory"
        name="textContentCriteria_documentCategory"
        class="custom-select"
      >
        <option
          v-for="(docCategory, idx) in textualReportCategories"
          :key="idx"
          :value="idx"
        >
          {{ docCategory }}
        </option>
      </select>
      <input
        v-model="value"
        name="textContentCriteria_input"
        class="form-control col p-1"
        @keyup.enter="addFilter"
      >
      <input
        v-if="specifyWordDistance"
        v-model="wordDistance"
        class="form-control col-1 text-center"
      >
      <div class="input-group-append">
        <button
          name="textContentCriteria_ok"
          class="col-auto btn btn-primary"
          type="button"
          @click="addFilter"
        >
          Ok
        </button>
      </div>
    </div>
    <div
      v-else
      class="col-12"
    >
      <div class="row">
        <select
          v-model="presence"
          name="textContentCriteria_presence"
          class="custom-select col-12 mb-2"
        >
          <option
            v-for="opt in filterOptions"
            :key="opt"
            :value="opt"
          >
            {{ filterData.subFilters[0].labels[opt] }}
          </option>
        </select>
        <select
          v-if="specifyDocumentCategory"
          v-model="documentCategory"
          name="textContentCriteria_documentCategory"
          class="custom-select col-12 mb-2"
        >
          <option
            v-for="(docCategory, idx) in textualReportCategories"
            :key="idx"
            :value="idx"
          >
            {{ docCategory }}
          </option>
        </select>
        <div class="input-group mb-2">
          <input
            v-model="value"
            name="textContentCriteria_input"
            class="form-control col p-1"
            @keyup.enter="addFilter"
          >
          <input
            v-if="specifyWordDistance"
            v-model="wordDistance"
            class="form-control col-2 text-center"
          >
          <div class="input-group-append">
            <button
              name="textContentCriteria_ok"
              class="col-auto btn btn-primary"
              type="button"
              @click="addFilter"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      :class="isLegacy ? ['col-8', 'pl-5'] : []"
    >
      <div
        v-if="isLegacy && extendedSearch && presence === 'absent'"
        class="form-check"
      >
        <input
          id="hard-absent-content-checkbox"
          v-model="hardAbsentContent"
          type="checkbox"
          class="form-check-input"
        >
        <label
          for="hard-absent-content-checkbox"
          class="form-check-label"
        >
          Exclusion complète des séjours contenant les mot-clés
        </label>
      </div>
      <div v-if="extendedSearch && (presence === 'present' || hardAbsentContent)">
        <div class="form-check">
          <input
            id="include-antecedent-checkbox"
            v-model="includeAntecedents"
            type="checkbox"
            class="form-check-input"
          >
          <label
            for="include-antecedent-checkbox"
            class="form-check-label"
          >
            Inclure les antécédents
          </label>
        </div>
        <div class="form-check">
          <input
            id="include-family-antecedent-checkbox"
            v-model="includeFamilyAntecedents"
            type="checkbox"
            class="form-check-input"
          >
          <label
            for="include-family-antecedent-checkbox"
            class="form-check-label"
          >
            Inclure les antécédents familiaux
          </label>
        </div>
        <div class="form-check">
          <input
            id="include-word-distance-checkbox"
            v-model="specifyWordDistance"
            type="checkbox"
            class="form-check-input"
          >
          <label
            for="include-word-distance-checkbox"
            class="form-check-label"
          >
            Spécifier l'intervalle entre les mots
          </label>
        </div>
        <div
          v-if="isSearchManager"
          class="form-check"
        >
          <input
            id="document-category-checkbox"
            v-model="specifyDocumentCategory"
            type="checkbox"
            class="form-check-input"
          >
          <label
            for="document-category-checkbox"
            class="form-check-label"
          >
            Spécifier la catégorie de document
          </label>
        </div>
      </div>
      <button
        v-if="extendedSearch === false && (isLegacy || presence === 'present' || hardAbsentContent)"
        class="btn btn-link"
        @click="extendedSearch = true"
      >
        <small>Plus d'options…</small>
      </button>
    </div>
  </div>
</template>
