<script>
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import _ from 'lodash'
import { Bar } from 'vue-chartjs'
import { mapState } from 'vuex'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  components: { Bar },
  props: {
    chartTitle: { type: String, default: null },
    chartName: { type: String, default: null },
    chartUnit: { type: String, required: true },
    earlyLimit: { type: Object, required: true },
    lateLimit: { type: Object, required: true },
    statsInfo: { type: Object, required: true },
    chartInterval: { type: Number, default: 1 },
    chartLegend: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      stats: (state) => state.home.homeChartStats,
    }),
    labels() {
      let current = this.earlyLimit
      const unit = this.chartUnit
      const monthLabel = [
        'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
        'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
      ]
      const labels = []
      while (!current.isAfter(this.lateLimit)) {
        switch(this.chartUnit) {
          case 'day':
            labels.push(current.format('DD/MM/YY'))
            break
          case 'week':
            labels.push(current.startOf('week').format('DD/MM/YY'))
            break
          case 'month':
            labels.push(`${monthLabel[current.month()]} ${current.year()}`)
            break
          case 'year':
            labels.push(current.year())
            break
        }

        current = current.add(this.chartInterval, unit)
      }

      return labels
    },
    statsList() {
      if (!this.stats || !this.stats.length) {
        return []
      }

      const emptyStat = {}
      let current = this.earlyLimit
      const unit = this.chartUnit

      const statsList = []
      while (!current.isAfter(this.lateLimit)) {
        const stats = _.find(this.stats, (stat) => {
          switch(unit) {
            case 'day':
              return stat.day === current.date() && stat.month === (current.month() + 1) && stat.year === current.year()
            case 'week':
              return stat.week === current.isoWeek() && stat.year === current.isoWeekYear()
            case 'month':
              return stat.month === (current.month() + 1) && stat.year === current.year()
            case 'year':
              return stat.year === current.year()
          }

          return false
        })
        if (stats) {
          statsList.push({
            ...stats,
            countValidatedStay: stats.countTotalStay - stats.countTodoStay,
            valueValidatedStay: stats.valueTotalStay - stats.valueTodoStay,
          })
        } else {
          statsList.push(emptyStat)
        }

        current = current.add(this.chartInterval, unit)
      }

      return statsList
    },
    dataList() {
      const dataList = {}

      _.forEach(this.statsList, (stat) => {
        _.forEach(this.statsInfo, (info, name) => {
          if (!dataList[name]) {
            dataList[name] = []
          }
          dataList[name].push(stat[name])
        })
      })

      return dataList
    },
    chartData() {
      const dataList = this.dataList
      const labels = this.labels
      const datasets = []

      _.forEach(this.statsInfo, (info, name) => {
        datasets.push({
          label: info.label,
          data: dataList[name],
          backgroundColor: info.hidden ? 'rgba(0,0,0,0)' : info.color,
        })
      })

      return {
        labels,
        datasets,
      }
    },
    chartOptions() {
      let labelRotation = 0
      if (this.chartUnit === 'month') {
        labelRotation = 10
      }
      if (this.chartUnit === 'day' || this.chartUnit === 'week') {
        labelRotation = 45
      }

      return {
        maintainAspectRatio:false,
        responsive: true,
        plugins: {
          title: { display: this.chartTitle ? true : false, text: this.chartTitle },
          legend:{ display: this.chartLegend },
          tooltip: { mode: 'index', intersect: false },
        },
        hover: { mode: 'nearest', intersect: true },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: false,
              display: true,
              maxRotation: labelRotation,
              minRotation: labelRotation,
              padding: 5
            },
          },
          y: {
            stacked: true,
            grid: {
              display: true,
            }
          },
        }
      }
    }
  }
}
</script>

<template>
  <Bar
    :chart-data="chartData"
    :chart-options="chartOptions"
  />
</template>
