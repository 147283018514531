import { AbstractState } from '@sancare/ui-frontend-commons/src/store/types'

export enum ConditionOperatorEnum {
  AND = 'ET',
  OR = 'OU',
}

export type Condition = {
  id: number | string
  type: string
  value: string
}

export type ConditionGroup = {
  id: number | string
  criteriaList: Condition[]
}

export enum GroupSearchOpEnum {
  ANDOR = 'andOr',
  ORAND = 'orAnd',
}

export type AutomationGroup = {
  id: number
  name: string
  actions: AutomationAction[]
  automated: boolean
  type: AutomationTypeEnum
  searchOperators: GroupSearchOpEnum
  globalCriteriaGroup: ConditionGroup
  criteriaGroups: ConditionGroup[]
  absentGhmList: string[]
  presentGhmList: string[]
  alertNoCro: boolean
  alertNoCrh: boolean
  waitForAnesthesia: boolean
  fulfillAnapathRequests: boolean
  waitDuration: number
}

export enum AutomationTypeEnum {
  AI = 'ai',
  RULES = 'rules',
}

export enum AutomationModeEnum {
  WAITING = 'waiting',
  EXPIRED = 'expired',
  AUTOMATABLE = 'automatable',
}

export enum AutomationActionEnum {
  ADD_DP = 'add_dp',
  ADD_DR = 'add_dr',
  ADD_DAS = 'add_das',
}

export const actionLabels = {
  [AutomationActionEnum.ADD_DP]: 'Appliquer le DP',
  [AutomationActionEnum.ADD_DR]: 'Appliquer le DR',
  [AutomationActionEnum.ADD_DAS]: 'Appliquer un DAS',
}

export type AutomationAction = {
  id?: number
  action: AutomationActionEnum
  value: string
}

// todo: to be completed
export class AutomationState extends AbstractState {
  editedAutomationGroup: AutomationGroup
  automationStayListParams: {
    currentPage: number
    pageSize: number
    showSeen: boolean
    showStatus: boolean
    order: string
    reverseOrder: boolean
    automationMode: string
    automationGroupId?: number
  }
  automationStayList: {
    items: []
    pageCount: number
    totalCount: number
  }
  getAutomationGroupRequest: { fetching: boolean, error: string, ok: boolean } // todo: use ApiRequest when https://team-git.sancare.net/dev/ui/-/merge_requests/2312 merged
}
