import { Ref, ref, watch } from 'vue'

export default function useFixedHeight(isRefreshing: Ref<boolean>, htmlElement: Ref<HTMLElement | null>) {
  const elementHeight: Ref<string | null> = ref(null)
  watch(isRefreshing, () => {
    if (!isRefreshing.value || !htmlElement.value) {
      elementHeight.value = null
      return
    }
    const rect = htmlElement.value.getBoundingClientRect()
    elementHeight.value = `${rect.height}px`
  })

  return { elementHeight }
}
