import {
  BaseDrugEvent,
  CategoricalLabResult,
  DrugEvent,
  HealthConstant,
  LabResult,
  NamedEntity,
  RawDrugEvent,
  TextualHealthEntryType
} from '@sancare/ui-frontend-commons/src/types/health-data'
import { Dayjs } from 'dayjs'
import _ from 'lodash'

import { TextualHealthEntry } from '@/stay-displayer/health-data/types'
import { PredictedDiagnosis, PredictedMedicalAct } from '@/store/modules/diagnosis/types'
import { BaseAct, BaseCodedAct } from '@/store/modules/medical-act/types'
import { BasePatient, Patient, RawPatient } from '@/store/modules/patient/types'
import { KeywordSearch, TextProcessingIntersectionChunks } from '@/text-processing/types'

export enum StayStatusEnum {
  NEW = 'new',
  REVIEW = 'review',
  UNVALIDATED = 'unvalidated',
  VALIDATED = 'validated',
}

export enum ExternalPmsiStatusEnum {
  NORMAL = 'normal',
  VALIDATED = 'validated',
  INVOICED = 'invoiced'
}

export enum StayNotificationEnum {
  NONE,
  NOT_READ,
  SOFT,
  HARD
}

export enum SeenStatusEnum {
  SEEN,
  UNSEEN,
  SEEN_AND_UPDATED,
  ASSIGNED,
  NEWLY_ASSIGNED,
  ASSIGNED_AND_UPDATED
}

export type StayStatus = {
  notification: StayNotificationEnum
  statusValue: SeenStatusEnum
  user?: {
    username: string
  } | null
}

export interface BaseStay<TDate, TPatient extends BasePatient<TDate>, TDrugEvent extends BaseDrugEvent<TDate>> {
  id: number
  administrativeLocalStayId: number
  patient: TPatient
  patientAge: number
  stayStart: TDate
  stayEnd: TDate
  stayDuration: number
  lastPredictionUpdate: TDate | null
  lastHealthDataUpdate: TDate | null
  lastExternalPmsiUpdate: TDate | null
  status: StayStatusEnum
  reports: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
  categoricalLabResults: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
  drugEvents: TDrugEvent[]
  namedEntities: NamedEntity[]
  healthConstants: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
  labResults: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
  textualHealthEntries: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
  comment: string | null
  staySavedSearches: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
  isLoading?: boolean
  savedSearchIntersectionChunks?: TextProcessingIntersectionChunks[]
  additionnalKeywordSearches?: KeywordSearch[]
  additionnalKeywordChunks?: object
}
export type RawStay = BaseStay<string, RawPatient, RawDrugEvent>
export type Stay = BaseStay<Dayjs, Patient, DrugEvent>

export interface BaseSummaryUnit<
  TDate,
  TAct extends BaseAct,
  TCodedAct extends BaseCodedAct<TDate, TAct>> {
  id: number
  predictedLabels: (PredictedDiagnosis | PredictedMedicalAct)[]
  codedMedicalActs: TCodedAct[]
}

export interface GroupingError {
  code: number
  category: string
  description: string
  critical: boolean
}

export type StayHealthData = {
  reports: TextualHealthEntry[]
  nurseReport: TextualHealthEntry[]
  targetNurseReport: TextualHealthEntry[]
  labResultComment: TextualHealthEntry[]
  medicalHistory: TextualHealthEntry[]
  surgeryLabel: TextualHealthEntry[]
  healthConstants: HealthConstant[]
  labResults: LabResult[]
  categoricalLabResults: CategoricalLabResult[]
  drugEvents: DrugEvent[]
  namedEntities: NamedEntity[]
}

export const formattedHealthData = (stay): StayHealthData => {
  const {
    reports,
    textualHealthEntries,
    healthConstants,
    labResults,
    categoricalLabResults,
    drugEvents,
    namedEntities,
  } = stay

  return {
    reports: _.filter(reports, (r) => r.content),
    nurseReport: _.filter(textualHealthEntries, (t) => t.type === TextualHealthEntryType.NURSE_REPORT && t.content),
    targetNurseReport: _.filter(textualHealthEntries, (t) => t.type === TextualHealthEntryType.TARGET_NURSE_REPORT && t.content),
    labResultComment: _.filter(textualHealthEntries, (t) => t.type === TextualHealthEntryType.LAB_RESULT_COMMENT && t.content),
    medicalHistory: _.filter(textualHealthEntries, (t) => t.type === TextualHealthEntryType.MEDICAL_HISTORY && t.content),
    surgeryLabel: _.filter(textualHealthEntries, (t) => t.type === TextualHealthEntryType.SURGERY_LABEL && t.content),
    healthConstants: healthConstants,
    labResults: labResults,
    categoricalLabResults: _.filter(categoricalLabResults, (r) => r.value),
    drugEvents: drugEvents,
    namedEntities: namedEntities,
  }
}
