import {
  CategoricalLabResult,
  DrugEvent,
  HealthConstant,
  LabResult,
} from '@sancare/ui-frontend-commons/src/types/health-data'
import _ from 'lodash'

import { PredictedLabelType } from '@/store/modules/diagnosis/types'

import { CodingSelection } from '../types'

function getHighlightedDrugEvent(drugEvents: DrugEvent[], selection: CodingSelection, displayedRumIdx: number) {
  return drugEvents.find((drugEvent) => {
    return selection && drugEvent.matchOrigins && (
      _.filter(Object.values(PredictedLabelType), (labelType) => {
        return labelType === selection.labelType
            && drugEvent.matchOrigins.predictions[displayedRumIdx][labelType]
              .indexOf(selection.labelReference) !== -1
      }).length
        || _.intersection(selection.savedSearchIds, drugEvent.matchOrigins.savedSearchIds).length
    )
  })
}

function getHighlightedHealthData(healthData: HealthConstant[] | LabResult[] | CategoricalLabResult[], selection: CodingSelection, displayedRumIdx: number) {
  // see: https://github.com/microsoft/TypeScript/issues/44373, TypeScript >5.2.0 fixes it
  const fixedHealthData: Array<typeof healthData[number]> = healthData

  return _.find(fixedHealthData, (hd) => {
    return selection && hd.matchOrigins && (
      _.filter(Object.values(PredictedLabelType), (labelType) => {
        return labelType === selection.labelType && hd.matchOrigins.predictions[displayedRumIdx][labelType].indexOf(selection.labelReference) !== -1
      }).length
      || _.intersection(selection.savedSearchIds, hd.matchOrigins.savedSearchIds).length
    )
  })
}

export {
  getHighlightedDrugEvent,
  getHighlightedHealthData,
}
