<script>
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'

import { hasPermission, RoleEnum, savedSearchPermissions } from '@/misc/permissions/permissionsHelper'
import router from '@/router'
import { doesSearchIdExist, saveCurrentSavedSearch } from '@/saved-searches/helpers'
import { DEFAULT_SEARCH_ID } from '@/saved-searches/types'
import StayListCriteriaParameters from '@/stay-list/StayListCriteriaParameters.vue'
import { ModeEnum, PmsiTypeEnum, PrivacyEnum } from '@/store/modules/settings/types'

import CriteriaDisplayer from '../filters/CriteriaDisplayer.vue'
import FilterBuilder from '../filters/FilterBuilder.vue'
import SavedSearchDetailsVue from '../saved-searches/SavedSearchDetails.vue'
import ShowActiveSearchesModal from '../saved-searches/ShowActiveSearchesModal.vue'

export default {
  components: {
    'show-active-searches-modal': ShowActiveSearchesModal,
    'filter-builder': FilterBuilder,
    'criteria-displayer': CriteriaDisplayer,
    'saved-search-details': SavedSearchDetailsVue,
    'stay-list-criteria-parameters': StayListCriteriaParameters,
  },
  props: {
    mode: { type: String, required: true },
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      required: true,
    },
  },
  emits: ['clear-message'],
  data() {
    return {
      searchError: null,
      searchSuccess: null,
      ModeEnum,
      PmsiTypeEnum,
      savedSearchPermissions,
    }
  },
  computed: {
    PrivacyEnum() {
      return PrivacyEnum
    },
    ...mapGetters(['shortHandStayListParams']),
    ...mapState({
      addSavedSearchRequest: (state) => state.savedSearches.addSavedSearchRequest,
      editSavedSearchRequest: (state) => state.savedSearches.editSavedSearchRequest,
      fetchSavedSearchesRequest: (state) => state.savedSearches.fetchSavedSearchesRequest,
      params: (state) => state.stayList.stayListParams,
      isSearchCreation: (state) => state.stayList.stayListParams.searchCreation,
      isSearchEdition: (state) => doesSearchIdExist(state.stayList.stayListParams.searchEdition),
      isAdmin: (state) => state.login.userRole === 'ROLE_SANCARE_DEV',
      isSearchEditor: (state) => hasPermission(RoleEnum.ROLE_SANCARE_SEARCH_MANAGER, state.login.userRole),
      currentSearch: (state) => state.stayList.currentSearch,
      currentSearchEdited: (state) => state.stayList.currentSearchEdited,
      currentUser: (state) => state.settings.currentUser,
      currentUsername: (state) => state.login.username,
      activeSearches(state) {
        let searchIds = state.stayList.activeSearches
        const searchesList = state.savedSearches.savedSearches

        searchIds = Array.isArray(searchIds) ? searchIds : [searchIds]
        searchIds = _.map(searchIds, (searchId) => parseInt(searchId))

        return _.filter(searchesList, (search) => searchIds.indexOf(search.id) !== -1)
      },
      editionInProgress: (state) => state.stayList.editionInProgress,
    }),
    isOwner() {
      return this.currentSearch.user === null || this.currentSearch.user.username === this.currentUsername
    },
    isSancareUser() {
      return this.$store.state.settings.currentUser.sancareUser
    },
    activeSearchIds() {
      return this.activeSearches.map((s) => s.id)
    },
  },
  watch: {
    addSavedSearchRequest(req) {
      if (req.error) {
        this.searchSuccess = null
        this.searchError = req.error
      } else if (req.ok) {
        this.searchError = null
        this.searchSuccess = 'Recherche enregistrée'
      }
    },
    editSavedSearchRequest(req) {
      if (req.error) {
        this.searchSuccess = null
        this.searchError = req.error
      } else if (req.ok) {
        this.searchError = null
        this.searchSuccess = 'Modifications enregistrées'
      }
    },
  },
  mounted() {
    this.$store.dispatch('initStayList')
  },
  methods: {
    setParameter(type, value) {
      this.$store.commit('setStayListParam', { type, value })
      this.onEdit()
      this.$store.dispatch('refreshStayList', { resetStaySelection: type !== 'pageSize' })
      this.$store.dispatch('refreshStayListUrl', {})
    },
    setSearchName(name) {
      this.$store.commit('setSearchName', name)
      this.onEdit()
    },
    setSearchPrivacy(privacy) {
      this.$store.commit('setSearchPrivacy', privacy)
      this.onEdit()
    },
    setSearchCategory(category) {
      if (!this.userCanEditSearchCategory) {
        return
      }

      this.$store.commit('setSearchCategory', category)
      this.onEdit()
    },
    addRegularCriteria({ type, value }) {
      this.$store.commit('addRegularCriteria', { type, value, criteriaGroupId: null })
      this.onEdit()
      this.$store.dispatch('refreshStayList', { resetStaySelection: true })
      // this.$store.dispatch('refreshStayListUrl', {});
    },
    replaceRegularCriteria({ type, value }) {
      this.$store.commit('replaceRegularCriteria', { type, value, criteriaGroupId: null })
      this.onEdit()
      this.$store.dispatch('refreshStayList', { resetStaySelection: true })
      this.$store.dispatch('refreshStayListUrl', {})
    },
    removeCriteria({ criteriaGroupId, criterion }) {
      this.$emit('clear-message')
      this.$store.commit('removeAdvancedCriteria', { criteriaGroupId, criterion })
      this.$store.dispatch('refreshStayList', {})
    },
    onEdit() {
      this.searchError = null
      this.searchSuccess = null
    },
    clearCriteria() {
      const refreshUrl = this.currentSearch.criteriaGroups.length > 0 || this.currentSearch.globalCriteriaGroup.criteriaList.length > 0

      this.$store.commit('clearCurrentSearchCriteria')
      this.searchError = null

      this.$store.dispatch('refreshStayList', { resetStaySelection: true })
      if (!refreshUrl) {
        return
      }
      this.$store.dispatch('refreshStayListUrl', {})
    },
    resetEdition() {
      this.onEdit()
      this.$store.dispatch('resetEdition', {})
    },
    loadSavedSearch(search) {
      if (search) {
        this.$store.commit('loadSavedSearch', search)
      } else {
        this.$store.commit('newSavedSearch')
      }
      this.setParameter('searchEdition', DEFAULT_SEARCH_ID)
    },
    editSelectedSearch(search) {
      this.$store.commit('loadSavedSearch', search)
      const stayListQuery = { ...this.shortHandStayListParams, edit: search.id, searchId: undefined }
      const newRoute = {
        name: 'staylist',
        query: stayListQuery,
        params: { pmsiType: this.pmsiType },
      }

      this.$store.commit('setStayListParam', { type: 'searchEdition', value: search.id })

      router.push(newRoute)
    },
    async saveSavedSearch() {
      if (!this.isSearchCreation && !this.isSearchEdition) {
        this.searchError = 'Aucune recherche en cours d\'édition'
        return
      }

      if (!this.currentSearch.name || this.currentSearch.name === '') {
        this.searchError = 'Nom de la recherche invalide'
        return
      }

      if (!this.currentSearch.privacy || this.currentSearch.privacy === '') {
        this.searchError = 'Niveau de partage incorrect'
        return
      }

      if (!this.currentSearch.savedSearchCategory.name || this.currentSearch.savedSearchCategory.name === '') {
        this.searchError = 'Nom de la catégorie invalide'
        return
      }

      await saveCurrentSavedSearch()

      this.$store.commit('setInitialCriteriaToCurrentSearch')
      this.$store.commit('loadSavedSearch', this.currentSearch)
      this.$store.commit('setEditionInProgress', false)
    },
    canDisplayActiveSearchesProperties() {
      return _.isEmpty(this.activeSearches) === false && this.isSearchEdition === false
    },
    getSearchNameOrCategory() {
      if (this.activeSearches.length === 1) {
        return this.activeSearches[0].name
      }
      // TODO: find a way to display the current category, or the current search group's name
      // return this.activeSearches[0].savedSearchCategory.name;
      return ''
    },
    userCanEditSearchCategory() {
      return this.isSearchEditor || this.isOwner || this.currentSearch.privacy === PrivacyEnum.PUBLIC_SEARCH
    },
  }
}
</script>

<template>
  <div
    name="stayListCriteria"
    class="stay-list-criteria card"
    :class="{ 'opposite-mode': params.improveRecallMode === true }"
  >
    <div class="card-body">
      <h2
        v-if="params.improveRecallMode === true"
        class="recall-optimisation-mode-title"
      >
        Mode optimisation du rappel
      </h2>
      <h1
        v-if="canDisplayActiveSearchesProperties()"
      >
        {{ getSearchNameOrCategory() }}
      </h1>
      <stay-list-criteria-parameters
        :mode="mode"
        :pmsi-type="pmsiType"
        @edit="onEdit"
        @set-parameter="setParameter"
      />
      <hr v-if="isSearchCreation || isSearchEdition">
      <div v-if="isSearchCreation || isSearchEdition">
        <h2 class="mb-2">
          {{ isSearchCreation ? 'Création' : 'Modification' }} d'une {{ mode === ModeEnum.OPTIMIZABLE ? 'recherche' : 'liste de travail' }}
        </h2>
        <div
          v-if="fetchSavedSearchesRequest.fetching"
          class="loader loader-center"
        />
        <template v-else>
          <div class="row align-items-center">
            <div class="col-5 row">
              <div class="col-5">
                Nom
              </div>
              <div class="col-7">
                <input
                  :value="currentSearch.name"
                  type="text"
                  class="form-control"
                  @change="event => setSearchName(event.srcElement.value)"
                >
              </div>
            </div>
          </div>
          <div class="row my-1 align-items-center">
            <div class="col-5 row">
              <div class="col-5">
                Partage
              </div>
              <div class="col-7">
                <select
                  :value="currentSearch.privacy"
                  :disabled="!savedSearchPermissions.canEditPrivacy(currentSearch, currentUser)"
                  class="custom-select"
                  @change="event => setSearchPrivacy(event.srcElement.value)"
                >
                  <option value="privateSearch">
                    Privée
                  </option>
                  <option
                    v-if="currentSearch.type !== ModeEnum.QUALITY_STAYS && isSancareUser"
                    value="teamSearch"
                  >
                    Equipe
                  </option>
                  <option value="publicSearch">
                    Publique
                  </option>
                  <option
                    v-if="(currentSearch.privacy === 'sancareSearch' || isAdmin) && currentSearch.type != ModeEnum.QUALITY_STAYS"
                    :disabled="!isAdmin"
                    value="sancareSearch"
                  >
                    Sancare
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row my-1 align-items-center">
            <div
              v-if="PrivacyEnum.PRIVATE_SEARCH !== currentSearch.privacy"
              class="col-5 row"
            >
              <div class="col-5">
                Catégorie
              </div>
              <div class="col-7">
                <input
                  :value="currentSearch.savedSearchCategory.name"
                  :disabled="!userCanEditSearchCategory()"
                  type="text"
                  class="form-control"
                  @change="event => setSearchCategory(event.srcElement.value)"
                >
              </div>
            </div>
          </div>
        </template>
      </div>
      <hr>
      <div>
        <h2 class="mb-2">
          Critères
        </h2>
        <div class="mb-2">
          <filter-builder
            :mode="mode"
            :pmsi-type="pmsiType"
            :is-legacy="true"
            @add-criteria="addRegularCriteria"
            @replace-criteria="replaceRegularCriteria"
          />
        </div>
        <div v-if="(currentSearch.criteriaGroups && currentSearch.criteriaGroups.length) || (currentSearch.globalCriteriaGroup && currentSearch.globalCriteriaGroup.criteriaList.length)">
          <div
            v-if="!currentSearch.isAdvancedSearch"
          >
            <div
              v-if="currentSearch.globalCriteriaGroup !== null"
            >
              <criteria-displayer
                :criteria-group="currentSearch.globalCriteriaGroup"
                :display-grouped-criteria="true"
                :mode="mode"
                internal-operator=""
                @remove-criteria="removeCriteria"
              />
              <hr>
            </div>
            <div
              v-for="(criteriaGroup, idx) in currentSearch.criteriaGroups"
              :key="idx"
            >
              <criteria-displayer
                :criteria-group="criteriaGroup"
                :display-grouped-criteria="true"
                :mode="mode"
                internal-operator=""
                @remove-criteria="removeCriteria"
              />
            </div>
          </div>
          <div v-else>
            <saved-search-details
              :saved-search="currentSearch"
            />
          </div>
        </div>
      </div>
      <div class="row no-gutters align-items-center">
        <div class="col">
          <div
            v-if="searchError"
            class="text-danger"
          >
            {{ searchError }}
          </div>
          <div
            v-else-if="searchSuccess"
            class="text-success"
          >
            {{ searchSuccess }}
          </div>
        </div>
        <div class="col-auto">
          <button
            v-if="editionInProgress && (isSearchEdition || isSearchCreation)"
            :disabled="!editionInProgress || !currentSearch.name || !currentSearch.privacy"
            class="btn btn-link"
            @click="saveSavedSearch"
          >
            Enregistrer la {{ mode === ModeEnum.OPTIMIZABLE ? 'recherche' : 'liste de travail' }}
          </button>
        </div>
        <div class="col-auto">
          <button
            v-if="currentSearchEdited && (isSearchCreation || isSearchEdition)"
            class="btn btn-link"
            @click="resetEdition"
          >
            Annuler les modifications
          </button>
          <button
            v-else-if="(currentSearch.criteriaGroups && currentSearch.criteriaGroups.length) || (currentSearch.globalCriteriaGroup && currentSearch.globalCriteriaGroup.criteriaList.length)"
            class="btn btn-link"
            @click="clearCriteria"
          >
            Retirer les critères
          </button>
        </div>
        <div
          v-if="activeSearches.length"
          class="col-auto"
        >
          <show-active-searches-modal
            :active-search-ids="activeSearchIds"
            :pmsi-type="pmsiType"
            @load-saved-search="s => { editSelectedSearch(s) }"
          >
            <template #button>
              <button class="btn btn-link">
                {{ activeSearches.length > 1 ? 'Recherches sélectionnées' : 'Recherche sélectionnée' }}
              </button>
            </template>
          </show-active-searches-modal>
        </div>
      </div>
    </div>
  </div>
</template>
